<div class="container mb-5" id="container mb-5">
  <h1><a class="text-center mb-5 text-monospace" style="color: white" href="mailto:ricardo@dtmm.com.br">Contact</a></h1>
  <div class="col-md-8">
    <div>
      <h3 class="text-center mb-4 text-monospace">Send your data below:</h3>

      <form [formGroup]="emailForm" class="register-form">
        <div class="form-field">
        <label class="label-input">Name:</label>
        <input type="text" formControlName="name" id="nome" class="form-control" placeholder="Enter your name">
      </div>
      <form [formGroup]="emailForm" class="register-form">
        <div class="form-field">
        <label class="label-input">Email:</label>
        <input type="email" formControlName="email" id="email" class="form-control" placeholder="Enter your email">
        <span class="error"
        *ngIf="emailForm.controls.email.errors &&
        emailForm.controls.email.errors.email">
        Enter a valid email address</span>
      </div>
      <form [formGroup]="emailForm" class="register-form">
        <div class="form-field">
        <label class="label-input">Phone:</label>
        <input type="phone" formControlName="phone" id="phone" class="form-control" placeholder="(99) 99999-9999">
        <span class="error"
        *ngIf="emailForm.controls.phone.errors &&
        emailForm.controls.phone.errors.maxlength ">
        The phone number has to be minus of 20 numbers</span>
        <span class="error"></span>
      </div>
      <form [formGroup]="emailForm" class="register-form">
      <div class="form-field">
        <label class="label-input">Message:</label>
        <textarea name="message" id="message" cols="30" rows="4" class="form-control" formControlName="message"
          placeholder="Enter your message"></textarea>
      </div>

      <div class="d-flex justify-content-end">
        <button type="submit" class="cadastrarPost" class="btn btn-outline-light w-50" [disabled]="emailForm.invalid" (click)="sendEmail()">Send</button>
      </div>
      </form>

