<div class="container">
  <div class="row mt-5 mb-5">
    <div class="col-md-4">
      <div class="card border-0" style="width: 18rem;">
        <div class="d-flex justify-content-center">
          <img src="./../../assets/perfil.jpg" class="card-img-top rounded-circle w-75" alt="Foto do usuário">
        </div>
        <div class="card-body">
          <h5 class="card-titletext-center">Ricardo Luis Machado</h5>
          <p class="card-texttext-center">Software Architect</p>
          <a href="https://github.com/wolwerr" target="_blank"
          class="btn btn-outline-light w-100">Github</a>
          <a href="https://www.linkedin.com/in/ricardolmachado/" target="_blank"
          class="btn btn-outline-light w-100">Linkedin</a>
          <div class="spacer">

            <h5 class="card-titletext-center">Resume</h5> 
            <a href="https://drive.google.com/file/d/1P4m604-ou-FUMbOXFJB2tiiVJZp9Nvst/view?usp=sharing" target="_blank"
            class="btn btn-outline-light w-100">CV em Português</a>
          
            <a href="https://drive.google.com/file/d/1YBeJFRlGnpeA0fmMhp7vkj1opaGl8SHO/view?usp=sharing" target="_blank"
            class="btn btn-outline-light w-100">English CV</a>
            
          </div>
          
          

          <!-- <hr class="mt-4 mb-4">
          <div class="search">
            <h6 class="mb-3 text-monospace text-center">Search by name</h6>
            <input type="text" class="form-control mb-3 w-100" placeholder="Enter a name" (input)="findComentByFilter($event, 'Name')">
            <input type="text" class="form-control mb-3 w-100" placeholder="Enter with some word" (input)="findComentByFilter($event, 'Message')">
        </div>         -->
        </div>
      </div>
    </div>

    <div class="col-md-8">
      <div>
        <h3 class="text-center mb-4 text-monospace">Send your comment below:</h3>

        <form [formGroup]="commentForm" class="register-form">
          <div class="form-field">
          <label class="label-input">Name:</label>
          <input type="text" formControlName="name" id="nome" class="form-control" placeholder="Enter your name">
        </div>
        <form [formGroup]="commentForm" class="register-form">
          <div class="form-field">
          <label class="label-input">Email:</label>
          <input type="email" formControlName="email" id="email" class="form-control" placeholder="Enter your email">
          <span class="error"
          *ngIf="commentForm.controls.email.errors &&
          commentForm.controls.email.errors.email">
          Enter a valid email address</span>
        <!-- </div>
        <form [formGroup]="commentForm" class="register-form">
          <div class="form-field">
          <label class="label-input">Password:</label>
          <input type="password" formControlName="password" id="password" class="form-control" placeholder="Enter your 6-digit password">
          <span class="error"
          *ngIf="commentForm.controls.password.errors &&
          commentForm.controls.password.errors.maxlength  ">
          The password must have a maximum of 6 characters</span>
          <span class="error"
          *ngIf="commentForm.controls.password.errors &&
          commentForm.controls.password.errors.minlength ">
          The password must have a maximum of 6 characters</span>
        </div> -->
        <form [formGroup]="commentForm" class="register-form">
        <div class="form-field">
          <label class="label-input">Message:</label>
          <textarea name="mensagem" id="mensagem" cols="30" rows="4" class="form-control" formControlName="message"
            placeholder="Enter your message"></textarea>
        </div>

        <div class="d-flex justify-content-end">
          <button type="submit" class="cadastrarPost" class="btn btn-outline-light w-50" [disabled]="commentForm.invalid"  (click)="onSubmit()">Publish</button>
        </div>
        </form>

        <hr class="mt-5 mb-5">

        <h3 class="text-center mb-5 text-monospace">Comments</h3>
        <div class="container">
          <div class="card mb-4" *ngFor="let coment of listComent">
            <div class="card-header">
              <h5 class="card-tile">Name: {{coment.name}}</h5>
            </div>
            <div class="card-body">
              <p class="card-text">Coment: {{coment.message}}</p>
              <p class="card-text">Send Date Comment: {{coment.inclusionDate}}</p>
              <button *ngIf="logado" class="btn btn-outline-light w-50" [routerLink]="['/coment-details', coment.id]" >Details</button>
            </div>

          </div>
        </div>






