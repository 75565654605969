<div class="container">
  <div class="row mt-5 mb-5">
    <div class="col-md-12">
      <link rel="stylesheet" type="text/css" href="home.component.css" media="screen" />
      <h1 class="text-center">Welcome!</h1>
    </div>
  </div>
<div class="row mb-5">
    <div class="col-md-12">
        <h3 class="text-center">"Passionate about Information Technology and Software Development"</h3>
        <p class="text-left">
            From the moment I discovered the world of Information Technology, I knew it was my true calling. With almost four years of dedicated experience in the field, I have honed my skills across a wide spectrum of technologies, including Java, Angular, TypeScript, JavaScript, NodeJS, Spring, Kafka, Oracle, Azure, Jenkins, Gitlab, and more.
        </p>
        <p class="text-left">
            Currently, I proudly serve as a Software Architect and Developer at Sonda, where I play a pivotal role in the Brazilian Central Bank, specifically focusing on the development and support of Pix, Brazil's most widely used money transfer product. My work here is not just about writing code; it's about crafting solutions that solve real-world problems and enhance the lives of countless individuals.
        </p>
        <p class="text-left">
            Beyond my professional role, I am a dedicated mentor for aspiring developers at a non-profit organization, providing guidance and support to those eager to pursue a career in IT. Additionally, I share my knowledge and insights through my YouTube channel, focusing on LinkedIn and guiding others in creating standout LinkedIn profiles.
        </p>
        <p class="text-left">
            My ultimate aspiration is to bring my expertise to an international company, collaborating with diverse professionals to share knowledge and continue expanding my horizons.
        </p>
        <p class="text-left">
          My proficiency spans a multitude of programming languages and technologies, evident in the projects showcased on my GitHub:
      </p>
      <ul>
          <li><strong>Backend:</strong> Java, Springboot, Hibernate, JSP, JPA, Kafka, PHP, Laravel, C#, .NET, Go</li>
          <li><strong>Frontend:</strong> JavaScript, TypeScript, HTML, CSS, React, AngularJs, VueJs</li>
          <li><strong>Database:</strong> PostgreSQL, Microsoft SQL Server, MySQL, JDBC/JPA, H2, Oracle SQL Developer</li>
          <li><strong>DevOps:</strong> Git, GitHub, AWS Cloud, Azure, Heroku, Vercel, Netlify, Docker, Railway</li>
          <li><strong>Agile Methodologies:</strong> Scrum, Kanban</li>
          <li><strong>Application Architectures:</strong> Microservices, MVC, Monolithic, API, REST, RESTful, Web Applications</li>
      </ul>
      
        <p class="text-left">
            I am perpetually driven by a thirst for knowledge, consistently seeking out additional courses and training to stay abreast of the latest advancements in technology. My passion for knowledge sharing extends to helping others in their professional journeys. It's not just about the destination; it's about the journey. I look forward to each new opportunity to learn, grow, and make a meaningful impact in the world of IT.
        </p>
        <p class="text-left">
            <strong>Contacts:</strong><br>
            E-mail: ricardo@dtmm.com.br<br>
            Phone: +55 (19) 99192-7277
        </p>
    </div>
</div>


  <div class="parallax"></div>

</div>
