<footer class="page-footer font-small bg-secondary pt-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12 d-flex justify-content-center">
        <h4 class="text-light">My Socials Media</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 d-flex justify-content-center">

        <ul class="social-network">
          <li><a href="https://github.com/wolwerr" target="_blank" class="fa fa-github-square github" title="Github" id="github">
              <fa-icon [icon]="faGithub"></fa-icon>
            </a></li>
          <li><a href="https://www.linkedin.com/in/ricardolmachado/" class="fa fa-linkedin-square linkedin" target="_blank" title="Linkedin" id="linkedin">
              <fa-icon [icon]="faLinkedin"></fa-icon>
            </a></li>
            <li><a href="https://www.instagram.com/wolwer/" class="fa fa-instagram-square instagram" target="_blank" title="Instagram" id="instagram">
              <fa-icon [icon]="faInstagram"></fa-icon>
            </a></li>
            <li><a href="https://www.tiktok.com/@ricardolmdev" class="fa fa-instagram-square instagram" target="_blank" title="TikTok" id="tiktok">
              <fa-icon [icon]="faTiktok"></fa-icon>
            </a></li>
            <li><a href="https://www.youtube.com/@DevRicardoMachado" class="fa fa-youtube-square youtube" target="_blank" title="Youtube" id="youtube">
              <fa-icon [icon]="faYoutube"></fa-icon>
            </a></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="footer-copyright bg-dark text-center py-3 ">
    <span class="text-light"> &copy; 2024 Copyright: </span>
    <a class="text-light" href="#">Ricardo Machado</a>
  </div>
</footer>
