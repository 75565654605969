
            <div class="container">
            <div class="row mt-5 mb-5">
            <div class="card" *ngIf="coment && card">
              <h1>Name: {{ coment.name }}</h1>
              <p>Id: {{ coment.id }}</p>
              <p>Email: {{ coment.email }}</p>
              <!-- <p>Password: {{ coment.password }}</p> -->
              <p>Comment: {{ coment.message }}</p>
              <p>Day of the comment: {{ coment.inclusionDate }}</p>
              <div class="actions">
                  <button class="error" class="btn btn-outline-light w-15 m-2" (click)="remove()">Remove</button>
                  <button class="btn btn-outline-light w-15 m-2" [routerLink]="['/coment']">Back</button>
              </div>
          </div>
        </div>



        <div class="row mt-5 mb-5">
          <div class="card" *ngIf="coment && card">
              <h3 class="text-center mb-4 text-monospace">Update data</h3>
              <form [formGroup]="editForm" class="register-form" (ngSubmit)="onSubmit()">
                  <!-- Name Field -->
                  <div class="form-field">
                      <label class="label-input">Name:</label>
                      <input type="text" formControlName="name" class="form-control">
                  </div>
                  
                  <!-- Email Field -->
                  <div class="form-field">
                      <label class="label-input">Email:</label>
                      <input type="email" formControlName="email" class="form-control">
                      <!-- Error for Email -->
                  </div>
                  
                  <!-- Password Field -->
                  <!-- <div class="form-field">
                      <label class="label-input">Password:</label>
                      <input type="password" formControlName="password" class="form-control"> -->
                      <!-- Error for Password -->
                  <!-- </div> -->
                  
                  <!-- Message Field -->
                  <div class="form-field">
                      <label class="label-input">Message:</label>
                      <textarea formControlName="message" cols="30" rows="4" class="form-control"></textarea>
                  </div>
  
                  <!-- Submit Button -->
                  <div class="d-flex justify-content-end">
                      <button type="submit" class="btn btn-outline-light w-50" [disabled]="editForm.invalid">Update</button>
                  </div>
              </form>
          </div>
      </div>
  </div>
            
              
          
      



